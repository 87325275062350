import { useRouter } from 'next/router';
import { useState } from 'react';
import ProductSmallTile from '../components/product-tile/product-small-tile';
import dildoImg from './../assets/vibrator_sm_tile.png';
import condomImg from './../assets/close-up-hand-holding-yellow-condom.jpg';
import vibratorImg from './../assets/person-with-sexual-toy.jpg';
import bdsmImg from './../assets/side-view-hands-wearing-pink-rope.jpg';
import womanizerTile from './../assets/womanizertile.png'
import logo from './../public/logo-pink.png'
import analTile from './../assets/analtile.png'
import { server } from './../config';
import styles from './index.module.css';
import Offer from './components/offer/offer';
export default function Home() {

  const [wominanizer, setWomanizer] = useState<any[] | null>(null);
  const [vibrator, setVibrator] = useState<any[] | null>(null);
  const [fifty, setFifty] = useState<any[] | null>(null);
  const [condoms, setCondoms] = useState<any[] | null>(null);
  const [handy, setHandy] = useState<any[] | null>(null); // [
  const [fetchedRandomProducts, setFetchedRandomProducts] = useState(false);

  const router = useRouter();
  if (!fetchedRandomProducts) {
    setFetchedRandomProducts(true);
    var funcs$ = [
      fetch(`${server}/api/category/getRandom?category=Womanizer`),
      fetch(`${server}/api/category/getRandom?category=Vibrator`),
      fetch(`${server}/api/category/getRandom?category=Fifty`),
      fetch(`${server}/api/category/getRandom?category=Kondomer`),
      fetch(`${server}/api/category/getRandom?category=Handy`),
    ]
    Promise.all(funcs$).then(async (response) => {
      setFetchedRandomProducts(true);
      setWomanizer(await response[0].json())
      setVibrator(await response[1].json())
      setFifty(await response[2].json())
      setCondoms(await response[3].json())
      setHandy(await response[4].json())
    }).catch((error) => {
      console.error(error);
      setFetchedRandomProducts(true);
    });
  }

  return (
    <div className={styles.container} style={{ zIndex: 1001, initialLetter: 1 }}>
      <main style={{marginTop: '16px'}}>
        <section>
          <h2>Våre rabattkoder</h2>
          <article className={styles.indexArticle} style={{textAlign: 'center'}}>
            <a href="https://www.nytelse.no/tt/?tt=12509_12_263403_rabattkodebsl24&r=%2F" target="_blank" rel="nofollow noopener">
            <span style={{fontWeight: 600}}>
              NYHET! Klikk og bruk rabattkode <span style={{fontSize: '1.4em'}}>BSL25</span> hos vår partner Nytelse.no og få 15% på alle kjøp.
            </span>
            </a><br/>
            <span style={{fontSize: '.6em'}}>Koden gjelder alle kjøp over 500kr, og kan ikke kombineres med andre tilbud.
            Varemerket Womanizer er unntatt rabattkoder pga. avtale med leverandør.</span>
          </article>
        </section>       
        <section>
          <h2>Populære kategorier</h2>
          <article className={styles.categoryContainer} >
            <div className={styles.category} onClick={() => router.push('sexleketoy/dildo')}><div>Dildo</div><div id="dildo"></div></div>
            <div className={styles.category} onClick={() => router.push('sexleketoy/vibrator')}><div>Vibrator</div><div id="vibrator"></div></div>
            <div className={styles.category} onClick={() => router.push('sexleketoy/kondomer')}><div>Kondomer</div><div id="condoms"></div></div>
            <div className={styles.category} onClick={() => router.push('sexleketoy/analsex')}><div>Analsex</div><div id="anal"></div></div>
            <div className={styles.category} onClick={() => router.push('sexleketoy/womanizer')}><div>Womanizer</div><div id="womanizer"></div></div>
            <div className={styles.category} onClick={() => router.push('sexleketoy/bdsm')}><div>BDSM</div><div id="bdsm" style={{backgroundPosition: 'bottom'}}></div></div>
          </article>
        </section>
        <br/>
        <article className={styles.indexArticle}>
            <img src={logo.src} style={{height: '42px', float: 'left', margin: '8px'}} alt="Hjerte" className="showInMobile"/>
            <h1 className={styles.title} style={{marginTop: 0}}>Billigesexleker.no - <small>Hvordan fungerer det?</small></h1>
          <p>
          Hvor er egentlig det beste stedet å kjøpe sexleketøy? Det kommer an på hvilket produkt du er ute etter.
          De fleste store norske nettbutikkene har et stort utvalg i dildoer, vibratorer, kondomer, glidemiddel og andre sexleketøy.
          Hva om du kunne søke i alle de store nettbutikkene samtidig? Vi har samlet alle produktene fra de største norske nettbutikkene som selger sexleketøy.
          </p>
          <p>
          Når du søker etter sexleketøy hos oss så søker du i en database med rundt 20 000 produkter.
          Produktene er importert fra produktkatalogen til de største norske nettbutikkene som selger sexleketøy.
          Når du velger et produkt f. eks en dildo eller en vibrator, så sjekker vi hvilke nettbutikker som har dette produktet, og gir deg en prisoversikt.
          Du kan så velge å gå til den nettbutikken som passer deg best for å handle.<br/><br/>
          <a className={styles.showExampleLink} href="sexleketoy/produkter/Womanizer%20Premium%202%20Klitorisstimulator">Vis prissammenligning for Womanizer Premium</a><br/><br/>
          <a className={styles.showExampleLink} href="rabattkoder">Se tilbud og rabattkoder</a>
          </p>
        </article>
        <br/>        
        <div className="showInMobile">
          <a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=101352" target="_blank" rel="nofollow noopener">
            <img src="https://www.partner-ads.com/no/visbanner.php?partnerid=30465&bannerid=101352"  alt="Sinful tilbud" />
          </a>
        </div>     
        <h2>Utvalgte produkter</h2>
        <h3>Handy Runkemaskin</h3>
        <section>
          {
            handy?.map && handy?.map(p =>
              <ProductSmallTile detailsPath='sexleketoy/produkter' product={p} size='normal' key={p._id} />
            )
          }
        </section>          
        <h3>Vibrator</h3>
        <section>
          {
            vibrator?.map && vibrator?.map(p =>
              <ProductSmallTile detailsPath='sexleketoy/produkter' product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <h3>Fifty Shades of Gray</h3>
        <section>
          {
            fifty?.map && fifty?.map(p =>
              <ProductSmallTile detailsPath='sexleketoy/produkter' product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <div className="hideInMobile hideOnPad">
          <br/>
          <a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=101292" target="_blank" rel="nofollow noopener">
            <img src="https://www.partner-ads.com/no/visbanner.php?partnerid=30465&bannerid=101292" alt="Massasjepistoler" />
          </a>
        </div>                      
        <h3>Kondomer</h3>
        <section>
          {
            condoms?.map && condoms?.map(p =>
              <ProductSmallTile detailsPath='sexleketoy/produkter' product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <h3>Womanizer</h3>
        <section>
          {
            wominanizer?.map && wominanizer?.map(p =>
              <ProductSmallTile detailsPath='sexleketoy/produkter' product={p} size='normal' key={p._id} />
            )
          }
        </section>        
      </main>
      <br />
      <div className="showInMobile" style={{ marginTop: '32px' }}>
        <a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=104846" target="_blank" rel="nofollow noopener"> <img src="https://www.partner-ads.com/no/visbanner.php?partnerid=30465&bannerid=104846"  alt="SinFul" /></a>
        <a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=88073" target="_blank" rel="nofollow noopener">
				  <img src="https://www.partner-ads.com/no/visbanner.php?partnerid=30465&bannerid=88073" alt="BDSM Dating" />
			  </a>
      </div>       
      <a className="hideInMobile hideOnPad" href="https://www.nytelse.no/tt/?tt=12509_1831570_263403_&amp;r=" target="_blank" rel="sponsored nofollow"><img src="https://ti.tradetracker.net/?c=12509&amp;m=1831570&amp;a=263403&amp;r=&amp;t=html" width="900" height="300" alt="" /></a>
      <style jsx>{`
        h3 {
          color: #333;
        }
        #dildo {
          background-image: url('${dildoImg.src}');
          background-size: cover;
        }
        #condoms {
          background-image: url('${condomImg.src}');
          background-size: cover;
        }
        #vibrator {
          background-image: url('${vibratorImg.src}');
          background-size: cover;
        }
        #bdsm {
          background-image: url('${bdsmImg.src}');
          background-size: cover;
        }
        #anal {
          background-image: url('${analTile.src}');
          background-size: cover;
        }
        #womanizer {
          background-image: url('${womanizerTile.src}');
          background-size: contain;
        }        
      `}</style>
    </div>
  );
}
